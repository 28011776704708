import Vue from "vue"
import VueTippy, { TippyComponent } from "vue-tippy"
import { config } from "@/config"
Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy
  flipDuration: 0,
  theme: "light-border",
  onShow: (options) => {
    return !!options.props.content
  },
  popperOptions: {
    modifiers: {
      hide: { enabled: false },
      preventOverflow: {
        enabled: false,
      },
    },
  },
})
Vue.component("tippy", TippyComponent)

export default (context, inject) => {
  // Set the function directly on the context.app object
  // if (process.client && context.$config.stage !== "dev" && context.$config.stage !== "development") {

  // }
}
